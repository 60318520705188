var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show":_vm.modalStatus,"body-classes":"p-0","modal-classes":"modal-dialog-centered modal-lg"},on:{"close":_vm.onModalClosed}},[_c('card',{staticClass:"border-0",attrs:{"type":"secondary","shadow":"","header-classes":"bg-white pb-5","body-classes":"px-lg-5 py-lg-5"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"position-relative",on:{"submit":function($event){$event.preventDefault();return _vm.onSaved($event)}}},[(
            _vm.$wait.is('fetchCenters') ||
            _vm.$wait.is('createCenter') ||
            _vm.$wait.is('fetchCenter') ||
            _vm.$wait.is('updateCenter')
          )?_c('base-loader'):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.t('name'),"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('base-input',{attrs:{"label":_vm.t('center'),"required":"","error":errors[0]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.t('site_no'),"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('base-input',{attrs:{"label":_vm.t('site_no'),"required":"","error":errors[0]},model:{value:(_vm.site_no),callback:function ($$v) {_vm.site_no=$$v},expression:"site_no"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('base-button',{staticClass:"my-4",attrs:{"outline":"","type":"default"},on:{"click":_vm.onModalClosed}},[_vm._v(" "+_vm._s(_vm.t("button_cancel"))+" ")]),_c('base-button',{staticClass:"my-4",attrs:{"type":"success","nativeType":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.t("button_save"))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }