<template>
  <modal
    :show="modalStatus"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-lg"
    @close="onModalClosed"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-5"
      body-classes="px-lg-5 py-lg-5"
      class="border-0"
    >
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="onSaved" class="position-relative">
          <base-loader
            v-if="
              $wait.is('fetchCenters') ||
              $wait.is('createCenter') ||
              $wait.is('fetchCenter') ||
              $wait.is('updateCenter')
            "
          />
          <ValidationProvider
            :name="t('name')"
            rules="required|min:2"
            v-slot="{ errors }"
          >
            <base-input
              :label="t('center')"
              required
              v-model="name"
              :error="errors[0]"
            >
            </base-input>
          </ValidationProvider>
          <ValidationProvider
            :name="t('site_no')"
            rules="required|min:2"
            v-slot="{ errors }"
          >
            <base-input
              :label="t('site_no')"
              required
              v-model="site_no"
              :error="errors[0]"
            >
            </base-input>
          </ValidationProvider>

          <div class="d-flex justify-content-between">
            <base-button
              outline
              type="default"
              class="my-4"
              @click="onModalClosed"
            >
              {{ t("button_cancel") }}
            </base-button>
            <base-button
              type="success"
              class="my-4"
              nativeType="submit"
              :disabled="invalid"
            >
              {{ t("button_save") }}
            </base-button>
          </div>
        </form>
      </ValidationObserver>
    </card>
  </modal>
</template>
<script>
export default {
  name: "center-modal",
  data() {
    return {
      name: "",
      site_no: "",
    };
  },
  props: {
    modalStatus: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: false,
    },
  },
  computed: {
    passwordValidationRules() {
      return this.id ? "min:5" : "required|min:5";
    },
  },
  watch: {
    modalStatus: function (status) {
      if (status && !!this.id) {
        this.getCenter();
      }
    },
  },
  methods: {
    onModalClosed() {
      this.$emit("update:modalStatus", false);
      this.resetForm();
    },

    onSaved() {
      const payload = {
        name: this.name,
        site_no: this.site_no,
      };

      if (!this.id) {
        this.add(payload);
      } else {
        this.update(payload);
      }
    },

    getCenters() {
      this.$wait.start("fetchCenters");
      this.$store
        .dispatch("center/FETCH_CENTERS")
        .then((centers) => {
          this.centers = centers;
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchCenters");
        });
    },

    getCenter() {
      this.$wait.start("fetchAppcenter");
      this.$store
        .dispatch("center/FETCH_CENTER", this.id)
        .then((center) => {
          this.name = center.name;
          this.site_no = center.site_no;
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchAppcenter");
        });
    },

    add(center) {
      this.$wait.start("createCenter");
      this.$store
        .dispatch("center/ADD_CENTER", center)
        .then(() => {
          this.$emit("saved");
          this.showSuccess(this.t("message_center_added"));
          this.onModalClosed();
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("createCenter");
        });
    },

    update(center) {
      center.id = this.id;
      this.$wait.start("updateCenter");
      this.$store
        .dispatch("center/UPDATE_CENTER", center)
        .then(() => {
          this.$emit("saved");
          this.showSuccess(this.t("message_center_updated"));
          this.onModalClosed();
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("updateCenter");
        });
    },

    resetForm() {
      this.name = "";
      this.site_no = "";
    },
  },
  created() {
    this.getCenters();
  },
};
</script>
