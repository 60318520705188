<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <h1 class="display-3 text-white">{{ t("centers") }}</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-12">
          <card shadow type="secondary">
            <base-loader
              v-if="$wait.is('fetchCenters') || $wait.is('deleteCenter')"
            />
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-12 text-right">
                  <base-button
                    outline
                    type="primary"
                    icon="ni ni-fat-add"
                    @click="openCenterModal()"
                  >
                    {{ t("new_center") }}
                  </base-button>
                </div>
              </div>
            </div>
            <base-table
              class="table align-items-center table-flush"
              tbody-classes="list"
              :data="centers"
            >
              <template slot="columns">
                <th>{{ t("center") }}</th>
                <th>{{ t("actions") }}</th>
              </template>

              <template slot-scope="{ row }">
                <th>
                  {{ row.name }}
                </th>
                <td>
                  <base-button
                    type="primary"
                    outline
                    icon="ni ni-ruler-pencil"
                    size="sm"
                    @click="openCenterModal(row.id)"
                  ></base-button>
                  <base-button
                    outline
                    type="warning"
                    icon="ni ni-fat-remove"
                    size="sm"
                    @click="openDeleteConfirmModal(row.id)"
                  ></base-button>
                </td>
              </template>
            </base-table>
          </card>
        </div>
      </div>
    </div>
    <center-modal
      :modalStatus.sync="centerFormModal"
      :id="selectedCenterId"
      @saved="getCenters"
    />
    <delete-confirm-modal
      :modalStatus.sync="centerDeleteModal"
      :id="selectedCenterId"
      @confirm="deleteCenter"
    />
  </div>
</template>
<script>
import CenterModal from "./CenterModal/CenterModal";
import DeleteConfirmModal from "../components/Modals/deleteConfirmModal";

export default {
  name: "center",
  data() {
    return {
      centers: [],
      centerFormModal: false,
      centerDeleteModal: false,
      selectedCenterId: null,
    };
  },
  components: {
    CenterModal,
    DeleteConfirmModal,
  },
  methods: {
    getCenters() {
      this.$wait.end("fetchCenters");
      this.$store
        .dispatch("center/FETCH_CENTERS")
        .then((centers) => {
          this.centers = centers;
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchCenters");
        });
    },
    openCenterModal(id = null) {
      this.selectedCenterId = id;
      this.centerFormModal = true;
    },
    openDeleteConfirmModal(id = null) {
      this.selectedCenterId = id;
      this.centerDeleteModal = true;
    },
    deleteCenter() {
      this.$wait.end("deleteCenter");
      this.$store
        .dispatch("center/DELETE_CENTER", this.selectedCenterId)
        .then(() => {
          this.showSuccess(this.t("message_deleted"));
          this.getCenters();
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("deleteCenter");
        });
    },
  },
  created() {
    this.getCenters();
  },
};
</script>
